.slick-list {
	overflow-x: scroll;
}

::-webkit-scrollbar {
	display: none;
}

.slick-slide div {
	outline: none;
}

.slick-arrow::before {
	color: #5850EC;
}